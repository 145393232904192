import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/common/helper';
import { Constant } from 'app/config/constants';
import { Common } from 'app/model/entity/common';
import { Ticket } from 'app/model/entity/ticket';
import { ContentDayReservation } from 'app/model/entity/ticket-editor/content-day-reservation';
import { ApplicationDTO } from 'app/model/entity/ticket-editor/dto/application-DTO';
import { Is29hNotation, Stock } from 'app/model/entity/ticket-editor/stock';
import { CommonService } from 'app/service/common.service';
import { DataService } from 'app/service/data.service';
import { TicketEditorService } from 'app/service/ticket-editor.service';
import { AppState } from 'app/store/app.state';
import _ from 'lodash';
import moment from 'moment';
import { DatePickerDirective } from 'ng2-date-picker';
import { Subscription } from 'rxjs';

@Component({
  selector: 'tab-reservation',
  templateUrl: './tab-reservation.component.html',
  styleUrls: ['./tab-reservation.component.scss']
})
export class TabReservationComponent implements OnInit {
  @Input() tabSelected: number; // tab selected
  @Input() informationAccount: any;
  @Output() reservationManagementOutPut: EventEmitter<any> = new EventEmitter<any>();
  @Output() rservationRegistrationOutPut: EventEmitter<any> = new EventEmitter<any>();
  /**
   * constant
   */
  Constant = Constant;
  private readonly FORMAT_DATE = 'YYYY-MM-DD';

  /**
   * month selected
   */
  selectedMonthInCalendarMonth: any;

  selectedMonthInCalendarWeek: any;

  isPreviousMonth: boolean;

  /**
   * true if > finish month
   */
  isNextMonth: boolean;

  /**
   * year selected
   */
  selectedYear: number;

  /**
   * common object
   */
  commonObject: Common;

  /**
   * current date
   */
  currentDate: Date = new Date();

  /**
   * content days month
   */
  contentDaysMonth: Array<ContentDayReservation>;

  contentDaysWeek: Array<ContentDayReservation>;

  /**
   * list month
   */
  listMonth: Array<{ value: string; key: number }> = Array(
    { value: this.translateService.instant('schedule-merge.month-1'), key: 0 },
    { value: this.translateService.instant('schedule-merge.month-2'), key: 1 },
    { value: this.translateService.instant('schedule-merge.month-3'), key: 2 },
    { value: this.translateService.instant('schedule-merge.month-4'), key: 3 },
    { value: this.translateService.instant('schedule-merge.month-5'), key: 4 },
    { value: this.translateService.instant('schedule-merge.month-6'), key: 5 },
    { value: this.translateService.instant('schedule-merge.month-7'), key: 6 },
    { value: this.translateService.instant('schedule-merge.month-8'), key: 7 },
    { value: this.translateService.instant('schedule-merge.month-9'), key: 8 },
    { value: this.translateService.instant('schedule-merge.month-10'), key: 9 },
    { value: this.translateService.instant('schedule-merge.month-11'), key: 10 },
    { value: this.translateService.instant('schedule-merge.month-12'), key: 11 }
  );

  /**
   * array subscription
   */
  subscriptions: Array<Subscription> = new Array<Subscription>();

  /**
   * return value selects the time table
   */

  selectTable: string = 'weekly';

  /**
   * returns true if you choose to display details by month
   */

  isShowDetailByMonth: boolean = false;

  /**
   * returns true if you choose to display details by week
   */

  isShowDetailByWeek: boolean = false;
  listApp: ApplicationDTO[] = []; // list Application
  listTicket: Ticket[] = [];
  appIdSelected: string;
  ticketIdSelected: number;
  time: any;
  public languageKey: string;
  weekSelected: any;
  config: any;
  dayInMonthSelected: ContentDayReservation;
  stockSelectFromWeeklyCalendar: Stock;
  resultApiA1: any;

  constructor(
    private commonService: CommonService,
    public translateService: TranslateService,
    public readonly store: Store<AppState>,
    private ticketEditorService: TicketEditorService,
    private cdr: ChangeDetectorRef,
    private datePipe: DatePipe,
    private dataService: DataService
  ) {
    this.subscriptions.push(
      this.translateService.onLangChange.subscribe(() => {
        this.languageKey = this.commonService.getCommonObject().setting?.language;
        // multiple language column header
        this.listMonth = [
          { value: this.translateService.instant('schedule-merge.month-1'), key: 0 },
          { value: this.translateService.instant('schedule-merge.month-2'), key: 1 },
          { value: this.translateService.instant('schedule-merge.month-3'), key: 2 },
          { value: this.translateService.instant('schedule-merge.month-4'), key: 3 },
          { value: this.translateService.instant('schedule-merge.month-5'), key: 4 },
          { value: this.translateService.instant('schedule-merge.month-6'), key: 5 },
          { value: this.translateService.instant('schedule-merge.month-7'), key: 6 },
          { value: this.translateService.instant('schedule-merge.month-8'), key: 7 },
          { value: this.translateService.instant('schedule-merge.month-9'), key: 8 },
          { value: this.translateService.instant('schedule-merge.month-10'), key: 9 },
          { value: this.translateService.instant('schedule-merge.month-11'), key: 10 },
          { value: this.translateService.instant('schedule-merge.month-12'), key: 11 }
        ];
        this.updateConfig();
      })
    );
    this.subscriptions.push(this.store.select(state => state).subscribe());
    this.commonObject = this.commonService.getCommonObject();
    this.currentDate = Helper.getCurrentByTimezoneSetting(this.commonObject);
  }

  async ngOnInit(): Promise<void> {
    this.languageKey = this.commonObject?.setting?.language;
    if (!this.selectedMonthInCalendarMonth) {
      this.currentDate = new Date();
      this.selectedYear = _.cloneDeep(this.currentDate.getFullYear());
      this.selectedMonthInCalendarMonth = _.cloneDeep(this.currentDate.getMonth());
    }
    if (!this.selectedMonthInCalendarWeek) {
      this.weekSelected = this.convertDateToFormatYYYYMMDDD(this.currentDate);
      // this.selectedMonthInCalendarWeek = (moment(this.weekSelected, this.FORMAT_DATE).isoWeeks() + '').padStart(2, '0');
      this.selectedMonthInCalendarWeek = this.getWeekNumberStartingSunday(this.weekSelected);
    }
    this.contentDaysMonth = _.cloneDeep(
      Helper.getOneMonthCalendar(this.selectedMonthInCalendarMonth, this.selectedYear, this.commonObject)
    );
    this.contentDaysWeek = _.cloneDeep(
      Helper.getOneWeekCalendar(this.selectedYear, this.selectedMonthInCalendarWeek, this.commonObject, this.weekSelected)
    );

    await this.getAllApplication();
    this.updateConfig();
  }

  showPrevMonth(): void {
    this.isPreviousMonth = false;
    this.isNextMonth = false;
    this.selectedMonthInCalendarMonth = this.selectedMonthInCalendarMonth <= 0 ? 11 : this.selectedMonthInCalendarMonth - 1;
    this.selectedYear = this.selectedMonthInCalendarMonth == 11 ? this.selectedYear - 1 : this.selectedYear;
    this.contentDaysMonth = _.cloneDeep(
      Helper.getOneMonthCalendar(this.selectedMonthInCalendarMonth, this.selectedYear, this.commonObject)
    );
  }

  showNextMonth(): void {
    this.isNextMonth = false;
    this.isPreviousMonth = false;
    this.selectedMonthInCalendarMonth = this.selectedMonthInCalendarMonth >= 11 ? 0 : this.selectedMonthInCalendarMonth + 1;
    this.selectedYear = this.selectedMonthInCalendarMonth == 0 ? this.selectedYear + 1 : this.selectedYear;
    this.contentDaysMonth = _.cloneDeep(
      Helper.getOneMonthCalendar(this.selectedMonthInCalendarMonth, this.selectedYear, this.commonObject)
    );
  }

  /**
   * select month
   * @param month
   */
  selectMonth(month: any): void {
    this.selectedMonthInCalendarMonth = +month;
    // get calendar by selected month and selected year
    this.contentDaysMonth = _.cloneDeep(
      Helper.getOneMonthCalendar(this.selectedMonthInCalendarMonth, this.selectedYear, this.commonObject)
    );
  }

  /**
   * change display mode
   * @param time
   */
  changeDisplayMode(time: any): void {
    // xử lí nếu có 2 mode
  }

  /**
   * show Detail by Month
   */
  showDetailByMonth(day: ContentDayReservation): void {
    this.isShowDetailByMonth = !this.isShowDetailByMonth;
    if (this.isShowDetailByMonth) {
      this.dayInMonthSelected = day;
    } else {
      this.dayInMonthSelected = undefined;
    }
  }

  /**
   * show Detail by week
   */
  async showDetailByWeek(stock?: Stock): Promise<void> {
    if (this.stockSelectFromWeeklyCalendar?.stockId == stock.stockId) {
      this.isShowDetailByWeek = false;
      this.stockSelectFromWeeklyCalendar = undefined;
    } else {
      this.isShowDetailByWeek = true;
      this.stockSelectFromWeeklyCalendar = stock;
      const datePart = this.stockSelectFromWeeklyCalendar.reserveOn.split('T')[0];
      const [yearStr, monthStr] = datePart.split('-');
      const year = parseInt(yearStr, 10);
      const month = parseInt(monthStr, 10);
      await this.ticketEditorService
        .reservationPrices(
          this.informationAccount,
          this.stockSelectFromWeeklyCalendar.stockPartitionId,
          this.stockSelectFromWeeklyCalendar.ticketId,
          year,
          month,
          null
        )
        .subscribe(res => {
          if (!res) {
            return;
          }
          res.forEach(element => {
            if (
              element?.reservationPriceId == this.stockSelectFromWeeklyCalendar.reservationPriceId &&
              element?.reservationClassId == this.stockSelectFromWeeklyCalendar.reservationClassId
            ) {
              this.resultApiA1 = element;
            }
          });
        });
    }
  }

  getTitleSelected(appId: string, ticketId: number, mode: string): string {
    if (mode == 'app') {
      let indexApp = this.listApp.findIndex(e => e.appId == appId);
      if (indexApp == -1) {
        return '';
      } else {
        return this.listApp[indexApp].appName;
      }
    } else if (mode == 'ticket') {
      let indexTicket = this.listTicket.findIndex(e => e?.ticketId == ticketId);
      if (indexTicket == -1) {
        return '';
      }
      return this.languageKey == 'en' ? this.listTicket[indexTicket]?.ticketName?.en : this.listTicket[indexTicket]?.ticketName?.ja;
    }
  }

  changeDisplay(value: String): String {
    let temp = _.cloneDeep(value)?.toString();
    if (temp?.split('W')?.length > 7 && temp?.length > 20) {
      value = value?.substring(0, 19) + '...';
    } else if (value?.length > 35) {
      value = value?.substring(0, 33) + '...';
    }
    return value;
  }

  /**
   * get list application
   * @param appSelect
   */
  async getAllApplication(): Promise<void> {
    return new Promise<void>(resolve => {
      this.ticketEditorService.findAllApplication(this.informationAccount).subscribe(res => {
        this.listApp = Helper.convertResApplication(res);
        resolve();
      });
    });
  }

  async getTicketByAppId(): Promise<void> {
    if (!this.appIdSelected || this.appIdSelected == '-1') {
      this.clearValue('app');
    } else {
      await this.ticketEditorService.getTickets(this.informationAccount, this.appIdSelected, null, true).subscribe(res => {
        this.clearValue('app');
        this.listTicket = res;
      });
    }
  }

  clearValue(modeChange: string) {
    if (modeChange == 'app') {
      this.ticketIdSelected = -1;
      this.listTicket = [];
    }
    if (modeChange != 'time') {
      this.contentDaysMonth = _.cloneDeep(
        Helper.getOneMonthCalendar(this.selectedMonthInCalendarMonth, this.selectedYear, this.commonObject)
      );
      this.onDateChange();
    }
    this.isShowDetailByMonth = false;
    this.isShowDetailByWeek = false;
    this.dayInMonthSelected = undefined;
    this.stockSelectFromWeeklyCalendar = undefined;
    this.resultApiA1 = undefined;
  }
  /**
   * open date picker
   * @param time selected
   * @param isFrom true if open date picker time from
   */
  openDatePicker(picker: DatePickerDirective, time: any) {
    picker.api.open();
    this.addPseudoSpan();
    picker.api.moveCalendarTo(time ?? moment());
  }

  public addPseudoSpan(): void {
    // Remove existing span-new elements if any
    while (document.getElementById('span-new')) {
      document.getElementById('span-new').remove();
    }

    // Find elements with class dp-weekdays and add a new span-new element
    const dp = Array.prototype.slice.call(document.getElementsByClassName('dp-weekdays'));
    if (dp) {
      dp.forEach(e => {
        const element = document.createElement('span');
        element.id = `span-new`;
        element.classList.add('dp-calendar-weekday', 'abc');
        e.insertBefore(element, e.firstElementChild);
      });
    }
  }

  /**
   * update config for picker
   *
   * @param period
   */
  private updateConfig(): void {
    this.config = {
      showWeekNumbers: true,
      format: this.FORMAT_DATE,
      firstDayOfWeek: 'su',
      unSelectOnClick: false,
      locale: Helper.getLocale(this.languageKey)
    };
  }

  /**
   * onDateChange
   */
  onDateChange() {
    this.clearValue('time');
    //this.selectedMonthInCalendarWeek = (moment(this.weekSelected, this.FORMAT_DATE).isoWeeks() + '').padStart(2, '0');
    this.selectedMonthInCalendarWeek = this.getWeekNumberStartingSunday(this.weekSelected);
    const dataDate = new Date(this.weekSelected);
    const year = dataDate.getFullYear();
    this.contentDaysWeek = _.cloneDeep(
      Helper.getOneWeekCalendar(year, this.selectedMonthInCalendarWeek, this.commonObject, this.weekSelected)
    );
    this.cdr.detectChanges();
  }

  /**
   * convertDate
   * @param fullDate
   * @param languageKey
   * @returns
   */
  convertDate(fullDate, languageKey) {
    if (!fullDate) {
      return '';
    }
    // Parse the fullDate string into a Date object
    const date = new Date(fullDate);

    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    // Format the date based on the language key
    if (languageKey == 'jp') {
      return `${year}年${month}月${day}日`;
    } else if (languageKey == 'en') {
      return `${date.toLocaleString('en-US', { month: 'long' })} ${day}, ${year}`;
    }
  }

  /**
   * changeTicket
   */
  async changeTicket(): Promise<void> {
    if (this.ticketIdSelected && this.ticketIdSelected !== -1) {
      let time =
        this.selectTable == 'monthly'
          ? this.getStartAndEndDateOfMonth(this.contentDaysMonth)
          : this.getStartAndEndDateOfMonth(this.contentDaysWeek);

      let resApi = await this.ticketEditorService
        .getStockByStockPartitionId(this.informationAccount, null, this.ticketIdSelected, time.startDate, time.endDate)
        .toPromise();

      if (!resApi) {
        return;
      }
      this.clearValue('ticket');
      resApi.forEach(element => {
        if (!element.is29hNotation) {
          element.reserveOnIs29hNotation = this.convertToIs29hNotation(element.reserveOn, false);
        } else {
          let reserveOnClone = _.cloneDeep(element.reserveOn);
          element.reserveOn = this.convertTimeIs29h(reserveOnClone, true);
          element.reserveOnIs29hNotation = this.convertToIs29hNotation(reserveOnClone, true);
        }
      });

      if (this.selectTable == 'monthly') {
        this.contentDaysMonth = _.cloneDeep(
          Helper.getOneMonthCalendar(this.selectedMonthInCalendarMonth, this.selectedYear, this.commonObject)
        );
        this.contentDaysMonth.forEach(contentDay => {
          if (!contentDay.isOtherMonth) {
            const contentDate = this.datePipe.transform(contentDay.fullDate, 'yyyy-MM-dd');
            resApi.forEach(stock => {
              const stockDate = stock.reserveOn.split('T')[0];

              if (stockDate.includes(contentDate)) {
                if (!contentDay.stock) {
                  contentDay.stock = [];
                  contentDay.stock.push(stock);
                } else {
                  contentDay.stock.push(stock);
                }
              }
            });
          }
        });

        this.contentDaysMonth.forEach(e => {
          if (e.stock && e.stock.length > 0) {
            e.stock.sort(this.compareStock.bind(this));
          }
        });
      } else {
        this.onDateChange();
        this.contentDaysWeek.forEach(contentDay => {
          if (!contentDay.isOtherMonth) {
            const contentDate = this.datePipe.transform(contentDay.fullDate, 'yyyy-MM-dd');
            resApi.forEach(stock => {
              const stockDate = stock.reserveOn.split('T')[0];

              if (stockDate.includes(contentDate)) {
                if (!contentDay.stock) {
                  contentDay.stock = [];
                  contentDay.stock.push(stock);
                } else {
                  contentDay.stock.push(stock);
                }
              }
            });
          }
        });

        this.contentDaysWeek.forEach(e => {
          if (e.stock && e.stock.length > 0) {
            e.stock.sort(this.compareStock.bind(this));
          }
        });
      }
    } else {
      this.clearValue('ticket');
    }
  }

  /**
   * convertToIs29hNotation
   *
   * nếu is29hNotation thì lùi lại 1 ngày + thời gian tăng thêm 24h
   *
   * @param dateString
   * @param is29hNotation
   * @returns
   */
  convertToIs29hNotation(dateString: string, is29hNotation: boolean): Is29hNotation {
    if (!dateString) {
      return;
    }
    let day;
    let time;
    const array = dateString.split('T');
    if (!is29hNotation) {
      day = array[0];
      time = array[1].substring(0, 5);
    } else {
      const dayAfter = array[0];
      const date = new Date(dayAfter);
      day = date.setDate(date.getDate() - 1);
      day = this.datePipe.transform(day, 'yyyy-MM-dd');
      const hours = parseInt(array[1].substring(0, 2)) + 24;
      const formattedHours = hours.toString();
      const minutes = array[1].substring(3, 5);
      time = `${formattedHours}:${minutes}`;
    }

    return {
      day: day,
      time: time
    };
  }

  convertTimeIs29h(inputTime: string, is29hNotation: boolean = false): string {
    // Chuyển đổi chuỗi thời gian thành đối tượng Date
    if (!inputTime) {
      return;
    }
    const arrayDate = inputTime.split('T');
    let date = new Date(arrayDate[0]);
    if (is29hNotation) {
      // Lùi 1 ngày trước
      date.setDate(date.getDate() - 1);
      const hours = parseInt(arrayDate[1].substring(0, 2), 10) + 24;
      // Lấy các thành phần của thời gian
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');

      // Tạo chuỗi kết quả
      const outputTime = `${year}-${month}-${day}T${hours}${arrayDate[1].substring(2, arrayDate[1].length)}`;
      return outputTime;
    } else {
      return inputTime;
    }
  }

  /**
   * getStartAndEndDateOfMonth
   * @param contentDaysMonth
   * @returns
   */
  getStartAndEndDateOfMonth(contentDaysMonth: ContentDayReservation[]): { startDate: string; endDate: string } {
    const daysOfMonth = contentDaysMonth.filter(day => !day.isOtherMonth);
    if (daysOfMonth?.length === 0) {
      return { startDate: '', endDate: '' };
    }
    const startDate = this.convertDateToFormatYYYYMMDDD(new Date(daysOfMonth[0].fullDate));
    const endDate = this.convertDateToFormatYYYYMMDDD(new Date(daysOfMonth[daysOfMonth.length - 1].fullDate));

    return { startDate, endDate };
  }

  /**
   * convertDateToFormatYYYYMMDDD
   * @param date
   * @returns
   */
  convertDateToFormatYYYYMMDDD(date: Date): string {
    if (!date) {
      return '';
    }
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date
      .getDate()
      .toString()
      .padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  /**
   * compareStock
   * @param a
   * @param b
   * @returns
   */
  compareStock(a: Stock, b: Stock): number {
    if (a.reserveOnIs29hNotation.time < b.reserveOnIs29hNotation.time) return -1;
    if (a.reserveOnIs29hNotation.time > b.reserveOnIs29hNotation.time) return 1;

    if (a.tripId < b.tripId) return -1;
    if (a.tripId > b.tripId) return 1;

    if (a.reservationClassId < b.reservationClassId) return -1;
    if (a.reservationClassId > b.reservationClassId) return 1;

    return 0;
  }

  /**
   * getTheDayOfTheWeek
   * @param date
   * @returns
   */
  getTheDayOfTheWeek(date: Date): string {
    if (!date) {
      return '';
    }
    const dayOfWeekMapJp = ['日', '月', '火', '水', '木', '金', '土'];
    const dayOfWeekMapEn = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const dayOfWeek = this.languageKey == 'en' ? dayOfWeekMapEn[date.getDay()] : dayOfWeekMapJp[date.getDay()];

    if (this.languageKey == 'en') {
      return `${year}/${month}/${day} (${dayOfWeek})`;
    } else if (this.languageKey == 'jp') {
      return `${year}年${month}月${day}日(${dayOfWeek})`;
    }
  }

  /**
   * getLabelStockQuantity
   * @param contentday
   * @returns
   */
  getLabelStockQuantity(contentday: ContentDayReservation): string {
    if (!contentday || !contentday.stock) {
      return '';
    }
    if (contentday && contentday.stock && contentday.stock.length) {
      return Helper.formatString(this.translateService.instant(`ticket-manager.tab-reservation.other-item`), `${contentday.stock.length}`);
    }
  }

  /**
   * getLabgetLabelHeaderweeklySchedule
   * @param date
   * @returns
   */
  getLabgetLabelHeaderweeklySchedule(date: Date): string {
    const dayOfWeekMapJp = ['日', '月', '火', '水', '木', '金', '土'];
    const dayOfWeekMapEn = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const month = date.getMonth() + 1; // Tháng trong Date bắt đầu từ 0
    const day = date.getDate();
    const dayOfWeek = this.languageKey == 'en' ? dayOfWeekMapEn[date.getDay()] : dayOfWeekMapJp[date.getDay()];

    return `${month}/${day} ${dayOfWeek}`;
  }

  /**
   * rservationRegistration
   */
  rservationRegistration(): void {
    const reservatiomDetail = {
      ticketId: this.stockSelectFromWeeklyCalendar.ticketId,
      ticketName: this.stockSelectFromWeeklyCalendar.ticketName,
      reserveOn: this.stockSelectFromWeeklyCalendar.reserveOn,
      reservationClassId: this.stockSelectFromWeeklyCalendar.reservationClassId,
      tripId: this.stockSelectFromWeeklyCalendar.tripId,
      reservationStatus: 'approved'
    };
    this.rservationRegistrationOutPut.emit(reservatiomDetail);
  }

  /**
   * reservationManagement
   */
  reservationManagement(): void {
    const dataSearch = {
      reserveOn: this.stockSelectFromWeeklyCalendar.reserveOn,
      tripId: this.stockSelectFromWeeklyCalendar.tripId,
      reservationClassId: this.stockSelectFromWeeklyCalendar.reservationClassId
    };

    this.reservationManagementOutPut.emit(dataSearch);
  }

  /**
   * enable
   */
  enable() {
    this.changeTicket();
  }

  getWeekNumberStartingSunday(dateString: string): string {
    // Chuyển đổi chuỗi ngày thành đối tượng moment
    const date = moment(dateString, this.FORMAT_DATE);
    // Tính số tuần từ ngày đầu tiên của tuần đầu tiên đến ngày được chọn
    let weekNumber;
    if (this.languageKey == 'en') {
      weekNumber = date.day() == 0 ? moment(date).week() + 1 : moment(date).week();
    } else {
      weekNumber = moment(date).week();
    }

    weekNumber = weekNumber > 52 ? 1 : weekNumber;
    // Định dạng kết quả để có ít nhất 2 chữ số
    return weekNumber.toString().padStart(2, '0');
  }

  /**
   * isEmpty
   * @param value
   * @returns
   */
  isEmpty(value): boolean {
    if (!value) {
      if (typeof value == 'number' && value == 0) {
        return false;
      }
      return true;
    }
    return false;
  }

  /**
   * getTheRemainingSeats
   * @param maxReservationCount
   * @param currentReservationCount
   * @returns
   */
  getTheRemainingSeats(maxReservationCount: number, currentReservationCount: number) {
    if (!this.isEmpty(maxReservationCount) && !this.isEmpty(currentReservationCount)) {
      return `${maxReservationCount - currentReservationCount} / ${maxReservationCount}`;
    }
  }

  /**
   * isActiveButton
   */
  isActiveButton(): boolean {
    if (!this.stockSelectFromWeeklyCalendar) {
      return false;
    }
    let currentDate = new Date(this.stockSelectFromWeeklyCalendar?.reserveOnIs29hNotation?.day);
    currentDate.setHours(0, 0, 0, 0);
    const currentDateByTimeZone = Helper.getCurrentByTimezoneSetting(this.commonObject, true);
    return currentDate >= currentDateByTimeZone;
  }
}
