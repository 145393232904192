import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/common/helper';
import { Constant, FIELD_COMPONENT, MODULE_NAME, PERIOD } from 'app/config/constants';
import { DialogMessageComponent } from 'app/dialog/dialog-message/dialog-message.component';
import { Common } from 'app/model/entity/common';
import { ApplicationDTO } from 'app/model/entity/ticket-editor/dto/application-DTO';
import { CommonService } from 'app/service/common.service';
import { DialogService } from 'app/service/dialog.service';
import { MenuActionService } from 'app/service/menu-action.service';
import { TicketManagerService } from 'app/service/ticket-manager.service';
import { saveAs } from 'file-saver';
import _ from 'lodash';
import moment from 'moment';
import { DatePickerDirective } from 'ng2-date-picker';
import { Subscription } from 'rxjs';
@Component({
  selector: 'tab-report',
  templateUrl: './tab-report.component.html',
  styleUrls: ['./tab-report.component.scss']
})
export class TabReportComponent implements OnInit {
  @Input() tabSelected: number; // tab selected
  @Input() listApp: ApplicationDTO[]; // list Application
  Constant = Constant;
  MODULE_NAME = MODULE_NAME;
  FIELD_COMPONENT = FIELD_COMPONENT;
  /**
   * common
   */
  PERIOD = PERIOD;

  /**
   * array subscription
   */
  subscriptions: Array<Subscription> = new Array<Subscription>();

  /**
   * common object
   */
  private commonObject: Common;

  /**
   * language key
   */
  public languageKey: string;

  /**
   * object
   */
  period: number = PERIOD.DAILY;

  /**
   * payloadForm
   */
  public payloadForm: FormGroup;
  /**
   * Config for datepicker
   */
  config: any;

  constructor(
    private translateService: TranslateService,
    private commonService: CommonService,
    private menuActionService: MenuActionService,
    private dialogService: DialogService,
    private formBuilder: FormBuilder,
    private ticketManagerService: TicketManagerService
  ) {
    // Action menu
    this.subscriptions.push(
      // Clear setting
      this.menuActionService.actionClearSetting.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketManagerComponent] && this.tabSelected == Constant.REPORT_ENUM) {
          this.clearSearch();
        }
      }),
      // Returns the currently displayed language
      this.translateService.onLangChange.subscribe(() => {
        this.languageKey = this.commonService.getCommonObject().setting?.language;
        this.config = {
          showWeekNumbers: false,
          format: 'YYYY-MM-DD',
          firstDayOfWeek: 'mo',
          unSelectOnClick: false,
          locale: Helper.getLocale(this.languageKey)
        };
      })
    );

    this.payloadForm = this.formBuilder.group({
      appId: [],
      startDate: [],
      endDate: []
    });

    this.commonObject = this.commonService.getCommonObject();
  }

  ngOnInit(): void {
    this.languageKey = this.commonObject?.setting?.language;
    this.config = {
      showWeekNumbers: false,
      format: 'YYYY-MM-DD',
      firstDayOfWeek: 'mo',
      unSelectOnClick: false,
      locale: Helper.getLocale(this.languageKey)
    };
  }

  /**
   * open date picker
   * @param picker
   * @param time
   */
  openDatePicker(picker: DatePickerDirective, time: any): void {
    picker.api.open();
    this.addPseudoSpan();
    picker.api.moveCalendarTo(time ?? moment());
  }

  /**
   * add element date picker
   */
  addPseudoSpan(): void {
    while (document.getElementById('span-new')) {
      document.getElementById('span-new').remove();
    }
    return;
  }

  /**
   * Clear search
   */
  clearSearch() {
    this.payloadForm.patchValue({
      appId: '',
      startDate: undefined,
      endDate: undefined
    });
  }

  /**
   * getDetailItem
   */
  getDetailItem(): void {
    if (this.checkValidateBeforeGetApi()) {
      let payload = {
        appId: this.payloadForm.value.appId ?? '',
        startDate: this.payloadForm.value.startDate,
        endDate: this.payloadForm.value.endDate,
        status: ''
      };
      this.ticketManagerService.getItemList(payload).subscribe(
        response => {
          const contentDispositionHeader = decodeURIComponent(response.headers.get('Content-Disposition'));
          const fileName = contentDispositionHeader?.split("filename*=UTF-8''")[1]
            ? contentDispositionHeader?.split("filename*=UTF-8''")[1]
            : 'DetailItem.csv';
          const file = new File([response.body], fileName, {
            type: 'text/csv'
          });
          var reader = new FileReader();
          // Đặt sự kiện onload khi đọc file thành công
          reader.onload = event => {
            var fileContent = event.target.result;
            if (!fileContent || fileContent == '""') {
              this.dialogService.showDialog(DialogMessageComponent, {
                data: {
                  title: this.translateService.instant('dialog-error.title'),
                  text: this.translateService.instant('dialog-error.no-data-error')
                }
              });
              return;
            }
            saveAs(file);
          };
          reader.readAsText(file);
        },
        error => {
          if (error.status == Constant.NETWORK_ERROR_CODE) {
            this.dialogService.showDialog(DialogMessageComponent, {
              data: {
                title: this.translateService.instant('dialog-error.title'),
                text: this.translateService.instant('dialog-error.error-network-api')
              }
            });
            return;
          }
          const reader = new FileReader();
          reader.onload = (event: any) => {
            const errorData = JSON.parse(event.target.result);
            let errorMessage = errorData.message;
            this.dialogService.showDialog(DialogMessageComponent, {
              data: {
                title: this.translateService.instant('dialog-error.title'),
                text: errorMessage
              }
            });
          };
          reader.readAsText(error.error);
        }
      );
    }
  }

  /**
   * getDetailPlanTicket
   */
  getDetailPlanTicket(): void {
    if (this.checkValidateBeforeGetApi()) {
      let payload = {
        appId: this.payloadForm.value.appId ?? '',
        startDate: this.payloadForm.value.startDate,
        endDate: this.payloadForm.value.endDate
      };
      this.ticketManagerService.getPlanTicket(payload).subscribe(
        response => {
          const contentDispositionHeader = decodeURIComponent(response.headers.get('Content-Disposition'));
          const fileName = contentDispositionHeader?.split("filename*=UTF-8''")[1]
            ? contentDispositionHeader?.split("filename*=UTF-8''")[1]
            : 'DetailPlanTicket.csv';
          const file = new File([response.body], fileName, {
            type: 'text/csv'
          });
          var reader = new FileReader();
          // Đặt sự kiện onload khi đọc file thành công
          reader.onload = event => {
            var fileContent = event.target.result;
            if (!fileContent || fileContent == '""') {
              this.dialogService.showDialog(DialogMessageComponent, {
                data: {
                  title: this.translateService.instant('dialog-error.title'),
                  text: this.translateService.instant('dialog-error.no-data-error')
                }
              });
              return;
            }
            saveAs(file);
          };
          reader.readAsText(file);
        },
        error => {
          if (error.status == Constant.NETWORK_ERROR_CODE) {
            this.dialogService.showDialog(DialogMessageComponent, {
              data: {
                title: this.translateService.instant('dialog-error.title'),
                text: this.translateService.instant('dialog-error.error-network-api')
              }
            });
            return;
          }
          const reader = new FileReader();
          reader.onload = (event: any) => {
            const errorData = JSON.parse(event.target.result);
            let errorMessage = errorData.message;
            this.dialogService.showDialog(DialogMessageComponent, {
              data: {
                title: this.translateService.instant('dialog-error.title'),
                text: errorMessage
              }
            });
          };
          reader.readAsText(error.error);
        }
      );
    }
  }

  /**
   * getDetailCouponTicket
   */
  getDetailCouponTicket(): void {
    if (this.checkValidateBeforeGetApi()) {
      let payload = {
        appId: this.payloadForm.value.appId ?? '',
        startDate: this.payloadForm.value.startDate,
        endDate: this.payloadForm.value.endDate
      };
      this.ticketManagerService.getCouponTicket(payload).subscribe(
        response => {
          const contentDispositionHeader = decodeURIComponent(response.headers.get('Content-Disposition'));
          const fileName = contentDispositionHeader?.split("filename*=UTF-8''")[1]
            ? contentDispositionHeader?.split("filename*=UTF-8''")[1]
            : 'DetailCouponTicket.csv';
          const file = new File([response.body], fileName, {
            type: 'text/csv'
          });
          var reader = new FileReader();
          // Đặt sự kiện onload khi đọc file thành công
          reader.onload = event => {
            var fileContent = event.target.result;
            if (!fileContent || fileContent == '""') {
              this.dialogService.showDialog(DialogMessageComponent, {
                data: {
                  title: this.translateService.instant('dialog-error.title'),
                  text: this.translateService.instant('dialog-error.no-data-error')
                }
              });
              return;
            }
            saveAs(file);
          };
          reader.readAsText(file);
        },
        error => {
          if (error.status == Constant.NETWORK_ERROR_CODE) {
            this.dialogService.showDialog(DialogMessageComponent, {
              data: {
                title: this.translateService.instant('dialog-error.title'),
                text: this.translateService.instant('dialog-error.error-network-api')
              }
            });
            return;
          }
          const reader = new FileReader();
          reader.onload = (event: any) => {
            const errorData = JSON.parse(event.target.result);
            let errorMessage = errorData.message;
            this.dialogService.showDialog(DialogMessageComponent, {
              data: {
                title: this.translateService.instant('dialog-error.title'),
                text: errorMessage
              }
            });
          };
          reader.readAsText(error.error);
        }
      );
    }
  }

  /**
   * reportsReservation
   */
  reportsReservation(): void {
    if (this.checkValidateBeforeGetApi(true)) {
      let payload = {
        appId: this.payloadForm.value.appId ?? '',
        startDate: this.payloadForm.value.startDate,
        endDate: this.payloadForm.value.endDate
      };
      this.ticketManagerService.reportsReservation(payload).subscribe(
        response => {
          const fileNamesample = `${payload.appId ? payload.appId : ''}${'_'}${
            payload.startDate ? this.convertDate(payload.startDate) : ''
          }${'_'}${payload.endDate ? this.convertDate(payload.endDate) : ''}${'.csv'}`;
          const contentDispositionHeader = decodeURIComponent(response.headers.get('Content-Disposition'));
          const fileName = contentDispositionHeader?.split("filename*=UTF-8''")[1]
            ? contentDispositionHeader?.split("filename*=UTF-8''")[1]
            : fileNamesample;
          const file = new File([response.body], fileName, {
            type: 'text/csv'
          });
          var reader = new FileReader();
          // Đặt sự kiện onload khi đọc file thành công
          reader.onload = event => {
            var fileContent = event.target.result;
            if (!fileContent || fileContent == '""') {
              this.dialogService.showDialog(DialogMessageComponent, {
                data: {
                  title: this.translateService.instant('dialog-error.title'),
                  text: this.translateService.instant('dialog-error.no-data-error')
                }
              });
              return;
            }
            saveAs(file);
          };
          reader.readAsText(file);
        },
        error => {
          if (error.status == Constant.NETWORK_ERROR_CODE) {
            this.dialogService.showDialog(DialogMessageComponent, {
              data: {
                title: this.translateService.instant('dialog-error.title'),
                text: this.translateService.instant('dialog-error.error-network-api')
              }
            });
            return;
          }
          const reader = new FileReader();
          reader.onload = (event: any) => {
            const errorData = JSON.parse(event.target.result);
            let errorMessage = errorData.message;
            this.dialogService.showDialog(DialogMessageComponent, {
              data: {
                title: this.translateService.instant('dialog-error.title'),
                text: errorMessage
              }
            });
          };
          reader.readAsText(error.error);
        }
      );
    }
  }

  convertDate(inputDate: string): string {
    const date = new Date(inputDate);
    const year = date.getFullYear();
    const month = this.padZero(date.getMonth() + 1);
    const day = this.padZero(date.getDate());
    const hours = this.padZero(date.getHours());
    const minutes = this.padZero(date.getMinutes());
    const seconds = this.padZero(date.getSeconds());
    const formattedDate = `${year}${month}${day}${hours}${minutes}${seconds}`;
    return formattedDate;
  }

  padZero(number: number): string {
    return number < 10 ? '0' + number : number.toString();
  }

  /**
   * checkValidateBeforeGetApi
   * @returns
   */
  checkValidateBeforeGetApi(isReservation?: boolean): boolean {
    const startDate = this.payloadForm.get('startDate').value;
    const endDate = this.payloadForm.get('endDate').value;
    const currentDate = new Date();
    var year = currentDate.getFullYear();
    var month = ('0' + (currentDate.getMonth() + 1)).slice(-2);
    var day = ('0' + currentDate.getDate()).slice(-2);
    var formattedDate = year + '-' + month + '-' + day;
    if (!startDate) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('ticket-manager.tab-report.validate-startDate')
        }
      });
      return;
    } else if (!endDate) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('ticket-manager.tab-report.validate-endDate')
        }
      });
      return;
    } else if (startDate > formattedDate && !isReservation) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('ticket-manager.tab-report.validate-startDate-error')
        }
      });
      return;
    } else if (endDate < startDate) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('ticket-manager.tab-report.validate-endDate-error')
        }
      });
      return;
    } else if (endDate > formattedDate && !isReservation) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('ticket-manager.tab-report.validate-endDate-error1')
        }
      });
      return;
    } else if (moment(endDate).diff(moment(startDate), 'days') < 0 || moment(endDate).diff(moment(startDate), 'days') >= 31) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('ticket-manager.tab-report.validate-range')
        }
      });
      return;
    }
    return true;
  }

  /**
   * Get name app display
   * @param value nameApp
   * @returns
   */
  changeDisplay(value: String): String {
    if (!value) {
      return;
    }
    let temp = _.cloneDeep(value).toString();
    if (temp?.split('W')?.length > 7 && temp.length > 21) {
      value = value.substring(0, 21) + '...';
    } else if (value.length > 35) {
      value = value.substring(0, 36) + '...';
    }
    return value;
  }
}
