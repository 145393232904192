import { Component, OnInit } from '@angular/core';
import { Helper } from 'app/common/helper';
import { Constant } from 'app/config/constants';
import { ApplicationDTO } from 'app/model/entity/ticket-editor/dto/application-DTO';
import { CommonService } from 'app/service/common.service';
import { DataService } from 'app/service/data.service';
import { TicketEditorService } from 'app/service/ticket-editor.service';
import { TicketManagerService } from 'app/service/ticket-manager.service';

@Component({
  selector: 'ticket-manager',
  templateUrl: './ticket-manager.component.html',
  styleUrls: ['./ticket-manager.component.scss']
})
export class TicketManagerComponent implements OnInit {
  /**
   * Constant
   */
  Constant = Constant;

  /**
   * tab selected
   */
  tabSelected: number = Constant.SALES_RECORD_ENUM;

  listApp: ApplicationDTO[] = []; // list Application
  isRoot: boolean;
  informationAccount: any;
  dataSearchFromReservation: any;
  dataReservationRegistration: any;

  constructor(
    private dataService: DataService,
    private ticketService: TicketEditorService,
    private ticketManagerService: TicketManagerService,
    private commonService: CommonService
  ) {
    this.isRoot = this.commonService.getCommonObject().userIdString == Constant.ROOT;
  }

  ngOnInit(): void {
    this.ticketService.getInformationAccount().subscribe(async data => {
      this.informationAccount = data;
      await this.getAllApplication(data);
      this.ticketManagerService.setInformationAccount(data);
      this.dataService.sendData([Constant.IS_TAB_SALES_RECORD, true]);
    });
  }

  /**
   * choose tab
   * @param tabEnum
   */
  public chooseTab(tabEnum: any, otherFromTab?: boolean): void {
    if (this.tabSelected == tabEnum) {
      return;
    }
    if (!otherFromTab) {
      this.dataReservationRegistration = undefined;
      this.dataSearchFromReservation = undefined;
    }
    this.dataService.sendData([Constant.HAS_RESERVATION_SELECTED, false]);
    this.tabSelected = tabEnum;
    switch (this.tabSelected) {
      case Constant.RESERVATION_TM_ENUM: {
        this.dataService.sendData([Constant.IS_TAB_RESERVATION, true]);
        this.dataService.sendData([Constant.IS_TAB_SALES_RECORD, false]);
        this.dataService.sendData([Constant.IS_TAB_REPORT, false]);
        this.dataService.sendData([Constant.IS_TAB_RESERVATION_MANAGER, false]);
        break;
      }
      case Constant.REPORT_ENUM: {
        this.dataService.sendData([Constant.IS_TAB_REPORT, true]);
        this.dataService.sendData([Constant.IS_TAB_RESERVATION, false]);
        this.dataService.sendData([Constant.IS_TAB_SALES_RECORD, false]);
        this.dataService.sendData([Constant.IS_TAB_RESERVATION_MANAGER, false]);
        break;
      }
      case Constant.RESERVATION_MANAGER_ENUM: {
        this.dataService.sendData([Constant.IS_TAB_RESERVATION_MANAGER, true]);
        this.dataService.sendData([Constant.IS_TAB_REPORT, false]);
        this.dataService.sendData([Constant.IS_TAB_RESERVATION, false]);
        this.dataService.sendData([Constant.IS_TAB_SALES_RECORD, false]);
        break;
      }
      default: {
        this.dataService.sendData([Constant.IS_TAB_SALES_RECORD, true]);
        this.dataService.sendData([Constant.IS_TAB_REPORT, false]);
        this.dataService.sendData([Constant.IS_TAB_RESERVATION, false]);
        this.dataService.sendData([Constant.IS_TAB_RESERVATION_MANAGER, false]);
        break;
      }
    }
  }

  /**
   * get list application
   * @param appSelect
   */
  async getAllApplication(informationAccount: any): Promise<void> {
    return new Promise<void>(resolve => {
      this.ticketService.findAllApplication(informationAccount).subscribe(res => {
        this.listApp = Helper.convertResApplication(res);
        resolve();
      });
    });
  }

  /**
   * receiveLanguageTranslation
   * @param language
   */
  getDataSearch(data: any): void {
    this.dataSearchFromReservation = data;
    if (this.dataReservationRegistration) {
      this.dataReservationRegistration = undefined;
    }
    this.chooseTab(Constant.RESERVATION_MANAGER_ENUM, true);
  }

  getDatarservationRegistration(data: any) {
    this.dataReservationRegistration = data;
    if (this.dataSearchFromReservation) {
      this.dataSearchFromReservation = undefined;
    }
    this.chooseTab(Constant.RESERVATION_MANAGER_ENUM, true);
  }
}
